<template>
	<div class="device-detail">
		<div class="el-content">
			<div class="ddb-info">
				<div>
					<span class="ddb-info-name">{{ device.dev_alias }}</span>
					<span class="ddb-info-did">({{ device.did }})</span>
				</div>
				<div>
					<a-space>
						<a-button type="primary" @click="show.hostname = true"><i class="el-icon-edit"></i>修改设备名称</a-button>
						<a-button type="primary" @click="updateSwitchName"><i class="el-icon-switch-button"></i>修改开关名称</a-button>
						<a-button type="primary" @click="show.more_switch = true"><i class="el-icon-setting"></i>设置</a-button>
						<a-button type="primary" @click="showReadRevise"><i class="el-icon-warning-outline"></i>读值校正</a-button>
						<a-dropdown>
							<a-button type="primary"  @click.prevent>
								报警器设置<i class="el-icon-arrow-down el-icon--right"></i>
							</a-button>
						    <template #overlay>
								<a-menu>
									<a-menu-item @click="showSiren('mode')">设置模式</a-menu-item>
									<a-menu-item @click="showSiren('time')">设置时间</a-menu-item>
								</a-menu>
						    </template>
						</a-dropdown>
					</a-space>
				</div>
			</div>
			<!-- 设备开关 -->
			<div class="ddb-switch">
				<div class="ddb-switch-title">设备开关</div>
				<div class="ddb-switch-val" v-if="device.giz_info && device.giz_info.swInfo">
					<template v-for="(item,index) in device.giz_info.swInfo">
						<span  :key="index" v-if="index <= 4">
							<a-switch @change="swOpen(item.value,item.key)" v-model:checked="item.value"> </a-switch>
							<a-tooltip v-if="item.type">
								<template #title>
									<div>模式：{{ $gizRequest.getSwitchModeName(item.type) }}</div>
								</template>
								<span @click="showSwitchMode(item.key)">{{item.name}}</span>
							</a-tooltip>
							<span v-else>{{item.name}}</span>
						</span>
					</template>
					<span style="color: #999;font-size: 12px;">点击开关名称可设置开关模式</span>
				</div>
			</div>
			<div class="ddb-sensor" v-if="device.giz_info">
				<!-- A口传感器 -->
				<div class="ddb-sensor-item" v-for="(item,index,key) in device.giz_info.AInfo" :key="index">
					<a-popover placement="bottomRight">
					    <template #content>
							<div class="sensor-op">
								<div @click="showSensorForm('sensor','A',item,key+1 )">切换传感器</div>
								<div @click="showSensorForm('wit','A',item,index)">设置智控</div>
								<div @click="showSensorForm('witdel','A',item,index)">删除智控</div>
							</div>
					    </template>
					    <div class="ddb-sensor-item-edit">
					    	<i class="ri-settings-5-fill ri-lg"></i>
					    </div>
					</a-popover>
					<div class="ddb-sensor-item-type"><img src="@/assets/img/device/icon1.png" alt=""></div>
					<div class="ddb-sensor-item-top">
						<span>[A{{key+1}}]</span>
						<span v-if="item.name">{{item.name}}</span>
						<span v-else>未设置</span>
					</div>
					<div class="ddb-sensor-item-btm">
						<div v-if="item.params">
							<span>{{item.params.name}}：</span>
							<span>{{item.params.appValueCom}}</span>
						</div>
					</div>
				</div>
				<!-- D口传感器 -->
				<div class="ddb-sensor-item" v-for="(item,index,key) in device.giz_info.DInfo" :key="index">
						<a-popover placement="bottomRight">
						    <template #content>
								<div class="sensor-op">
									<div class="sensor-op-item" @click="showSensorForm('sensor','D',item,key+1 )">切换传感器</div>
									<a-popover placement="right">
									    <template #content>
											<span v-for="(val,ind) in item.params" :key="ind">
												<div
													class="sensor-op-item"
													v-if="val.port"
													style="text-align: center;cursor: pointer;" >
													<span @click="showSensorForm('wit','D',val,index)">{{val.name}}</span>
													<i class="ri-delete-bin-6-line ri-sm" @click="showSensorForm('witdel','D',val,index)"></i>
												</div>
											</span>
									    </template>
									    <div>设置智控</div>
									</a-popover>
								</div>
						    </template>
						    <div class="ddb-sensor-item-edit">
						    	<i class="ri-settings-5-fill ri-lg"></i>
						    </div>
						</a-popover>
					<div class="ddb-sensor-item-type"><img src="@/assets/img/device/icon1.png" alt=""></div>
					<div class="ddb-sensor-item-top">
						<span>[D{{key+1}}]</span>
						<span v-if="item.name">{{item.name}}</span>
						<span v-else>未设置</span>
					</div>
					<div class="ddb-sensor-item-btm">
						<div v-for="(val,ind) in item.params" :key="ind">
							<template v-if="val.name=='风向' ">
								<span>{{val.name}}：</span><span>{{$gizRequest.getWindDirection(val.appValue)}}</span>
							</template>
							<template v-else>
								<span>{{val.name}}：</span><span>{{val.appValueCom}}</span>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 本地定时 -->
		<div class="ddb-tables">
			<div class="ddb-tables-item">
				<div class="ddbti-nav">本地定时</div>
				<div class="ddbti-item" v-for="(item,index,key) in localTimer" :key="index">
					<div class="ddbti-item-index">{{key+1}}</div>
					<div class="ddbti-item-desc" @click="showLocalTimer(key+1,item.status)">{{item.desc ? item.desc :"未设置"}}</div>
					<div class="ddbti-item-switch">
						<a-switch v-model:checked="item.status" @change="setTimerStatus(key+1,item.status)"></a-switch>
						<a-button type="link" v-if="item.desc!='未设置'" @click="delTimer(key+1)">清空定时</a-button>
					</div>
				</div>
			</div>
			<div class="ddb-tables-item ddb-tables-item-two">
				<div class="ddbti-nav-two">智控管理</div>
				<a-collapse v-if="witInfo">
				    <a-collapse-panel key="1">
						<template #header><div :style="{color:witInfo.a1.info && witInfo.a1.info!='未设置' ? 'red':''}">【传感器A1】智能控制</div></template>
						<div class="ddbti-collapse-title">{{witInfo.a1.info}}</div>
					</a-collapse-panel>
					<a-collapse-panel key="2">
						<template #header><div :style="{color:witInfo.a2.info && witInfo.a2.info!='未设置' ? 'red':''}">【传感器A2】智能控制</div></template>
						<div class="ddbti-collapse-title">{{witInfo.a2.info}}</div>
					</a-collapse-panel>
					<a-collapse-panel key="3">
						<template #header><div :style="{color:witInfo.a3.info && witInfo.a3.info!='未设置' ? 'red':''}">【传感器A3】智能控制</div></template>
						<div class="ddbti-collapse-title">{{witInfo.a3.info}}</div>
					</a-collapse-panel>
					<a-collapse-panel key="4">
						<template #header><div :style="{color:witInfo.a4.info && witInfo.a4.info!='未设置' ? 'red':''}">【传感器A4】智能控制</div></template>
						<div class="ddbti-collapse-title">{{witInfo.a4.info}}</div>
					</a-collapse-panel>
					<a-collapse-panel key="5">
						<template #header><div :style="{color:witInfo.a5.info && witInfo.a5.info!='未设置' ? 'red':''}">【传感器A5】智能控制</div></template>
						<div class="ddbti-collapse-title">{{witInfo.a5.info}}</div>
					</a-collapse-panel>
					<a-collapse-panel key="6">
						<template #header><div :style="{color:$gizRequest.checkHasWit(witInfo.d1) ? 'red':''}">【传感器D1】智能控制</div></template>
						<div class="ddbti-collapse-title" v-for="(item,index) in witInfo.d1" :key="index">{{item.info}}</div>
					</a-collapse-panel>
					<a-collapse-panel key="7">
						<template #header><div :style="{color:$gizRequest.checkHasWit(witInfo.d2) ? 'red':''}">【传感器D2】智能控制</div></template>
						<div class="ddbti-collapse-title" v-for="(item,index) in witInfo.d2" :key="index">{{item.info}}</div>
					</a-collapse-panel>
					<a-collapse-panel key="8">
						<template #header><div :style="{color:$gizRequest.checkHasWit(witInfo.d3) ? 'red':''}">【传感器D3】智能控制</div></template>
						<div class="ddbti-collapse-title" v-for="(item,index) in witInfo.d3" :key="index">{{item.info}}</div>
					</a-collapse-panel>
				</a-collapse>
			</div>
		</div>

		<a-modal class="add-local-timer" v-model:visible="show.add_local_time" title="新增本地定时" @ok="setLocalTime" @cancel="show.add_local_time= false"  width="600px">
			<a-form ref="localTimerForm" :model="localTimerForm" :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="是否启用">
					<a-switch v-model:checked="localTimerForm.disabled" @change="checkLocalTimerForm('disabled')"></a-switch>
				</a-form-item>
				<a-form-item label="定时重复">
					<a-radio-group v-model:value="localTimerForm.type" @change="checkLocalTimerForm('type')">
					    <a-radio :value="0">分钟循环</a-radio>
					    <a-radio :value="1">小时循环</a-radio>
					    <a-radio :value="2">天循环</a-radio>
					</a-radio-group>
					<div style="font-size: 12px;color: #999;" v-if="localTimerForm.type == 0">选择分钟循环，时间设置只有分钟有效，小时无效</div>
					<div style="font-size: 12px;color: #999;" v-if="localTimerForm.type == 1">选择小时循环，时间设置只有分钟有效，小时无效</div>
				</a-form-item>
				<a-form-item label="定时开始时间">
					<a-time-picker v-model:value="localTimerForm.s_time" :value="moment(localTimerForm.s_time,'HH:mm')" format="HH:mm" @change="checkLocalTimerForm('s_time')"/>
				</a-form-item>
				<a-form-item label="定时结束时间" v-if="localTimerForm.type !=0 ">
					<a-time-picker v-model:value="localTimerForm.e_time" :value="moment(localTimerForm.e_time,'HH:mm')" format="HH:mm" @change="checkLocalTimerForm('e_time')"/>
				</a-form-item>
				<a-form-item label="执行动作"  v-if="localTimerForm.type !=0 ">
					<a-select v-model:value="localTimerForm.action"  @change="checkLocalTimerForm('action')">
						<a-select-option :value="0">定时开始关闭-定时结束启动</a-select-option>
						<a-select-option :value="1">定时开始启动-定时结束关闭</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="参与动作的选择">
					<a-checkbox-group v-model:value="localTimerForm.sw" @change="checkLocalTimerForm('sw')">
						<template v-for="(item,index) in device.giz_info.swInfo">
							<a-checkbox
								:value="'sw'+item.key"
								:key="index"
								v-if="item.key < 6">
								{{item.name}}
							</a-checkbox>
						</template>
					</a-checkbox-group>
					<span style="font-size: 12px;color: #999;" v-if="localTimerForm.type == 0">分钟循环仅支持点动模式开关</span>
				</a-form-item>
				<div class="add-local-timer-tip">当前设置-实时信息</div>
				<div class="add-local-timer-txt">
					{{localTimerForm.desc}}
				</div>
				<!-- <a-form-item >
					<a-button type="success" @click="setLocalTime">确认</a-button>
					<a-button type="infor" @click="show.add_local_time = false">取消</a-button>
				</a-form-item> -->
			</a-form>
		</a-modal>

		<a-modal class="update-switch-mode" v-model:visible="show.switch_mode" title="开关模式管理" @ok="setSwitchMode" @cancel="show.switch_mode= false"  width="600px">
			<a-form ref="networkTimerForm" :model="switchModeForm"  :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item>
					<a-radio v-model:value="switchModeForm.mode" label="switch">
						开关<i class="ri-shut-down-line ri-lg"></i>
					</a-radio>
				</a-form-item>
				<a-form-item>
					<a-radio v-model:value="switchModeForm.mode" label="lock">
						互锁<i class="ri-switch-fill ri-lg"></i>
					</a-radio>
				</a-form-item>
				<a-form-item>
					<a-radio v-model:value="switchModeForm.mode" label="time">
						点动<i class="ri-radio-button-line ri-lg"></i>
					</a-radio>
					<a-input-number v-if="switchModeForm.mode == 'time'" v-model:value="switchModeForm.time" :min="1" :max="255" label="点动时间"></a-input-number>
					<div class="table-tips" style="margin-left: 28px;">选择点动时间1-255秒</div>
				</a-form-item>
				<a-form-item>
					<a-radio v-model:value="switchModeForm.mode" label="delay">
						延时<i class="ri-timer-flash-line ri-lg"></i>
					</a-radio>
					<a-input-number v-if="switchModeForm.mode == 'delay'" v-model:value="switchModeForm.time" :min="1" :max="120" label="点动时间"></a-input-number>
					<div class="table-tips" style="margin-left: 28px;">延时动作取反时间1分钟~120分钟</div>
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal class="update-sensor" :title="'切换'+sensorForm.type+'口传感器'"  v-model:visible="show.sensor" @ok="setSensor" @cancel="show.sensor= false"  width="600px">
			<a-form ref="sensorForm" :model="sensorForm" :label-col="{span:4}" :wrapper-col="{span:16}">
				<template v-if="sensorForm.type == 'A'">
					<a-form-item label="选择A口切换">
						<a-select v-model:value="sensorForm.a_select_1">
							<a-select-option value="1">A1</a-select-option>
							<a-select-option value="2">A2</a-select-option>
							<a-select-option value="3">A3</a-select-option>
							<a-select-option value="4">A4</a-select-option>
							<a-select-option value="5">A5</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="选择传感器">
						<a-select v-model:value="sensorForm.a_select_2">
							<a-select-option :value="0">[0]删除传感器</a-select-option>
							<a-select-option v-for="(item, index) in a_type_arr" :key="index" :value="index" >[{{ index }}] {{ item }}</a-select-option>
						</a-select>
					</a-form-item>
				</template>
				<template v-if="sensorForm.type == 'D'">
					<a-form-item label="选择D口切换">
						<a-select v-model:value="sensorForm.d_select_1">
							<a-select-option value="1">D1</a-select-option>
							<a-select-option value="2">D2</a-select-option>
							<a-select-option value="3">D3</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="选择传感器">
						<a-select v-model:value="sensorForm.d_select_2">
							<a-select-option :value="0">[0]删除传感器</a-select-option>
							<a-select-option v-for="(item, index) in d_type_arr" :key="index" :value="index">[{{ index }}] {{ item }}</a-select-option>
						</a-select>
					</a-form-item>
				</template>
			</a-form>
		</a-modal>

		<a-modal class="update-sensor" title="设置智控" v-model:visible="show.wit" @ok="setWit" @cancel="show.wit= false"  width="700px">
			<a-form ref="witForm" :model="witForm" :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="设置上下限值">
					<a-slider v-model:value="witForm.val" range :max="witForm.max" :min="witForm.min" @change="checkWitForm('max')"></a-slider>
				</a-form-item>
				<a-form-item label="设置上限">
					<a-input-number v-model:value="witForm.val_s" @change="checkWitForm('min')" :max="witForm.max" :min="witForm.min"></a-input-number>
				</a-form-item>
				<a-form-item label="设置下限">
					<a-input-number v-model:value="witForm.val_e" @change="checkWitForm('min')" :max="witForm.max" :min="witForm.min"></a-input-number>
				</a-form-item>
				<a-form-item label="精度">
					<a-tag color="#999">{{witForm.accCom}}</a-tag>
				</a-form-item>
				<a-form-item label="每次触发时激活报警一次">
					<a-switch v-model:checked="witForm.sw5"  @change="checkWitForm('sw5')"></a-switch>
				</a-form-item>
				<a-form-item label="触发动作">
					<a-radio-group v-model:value="witForm.mode"  @change="checkWitForm('mode')">
					    <a-radio :value="0">上限启动/下限关闭</a-radio>
					    <a-radio :value="1">上限关闭/下线启动</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="执行选择">
					<a-checkbox-group v-model:value="witForm.sw"  @change="checkWitForm('sw')">
						<template v-for="(item,index) in device.giz_info.swInfo">
							<a-checkbox
								:value="'sw'+item.key"
								:key="index"
								v-if="item.key < 5">
								{{item.name}}
							</a-checkbox>
						</template>
					</a-checkbox-group>
				</a-form-item>
				<a-form-item label="启动时间区域智控">
					<a-switch v-model:checked="witForm.openTime"  @change="checkWitForm('openTime')"></a-switch>
				</a-form-item>
				<template v-if="witForm.openTime">
					<a-form-item label="智控起始时间">
						<a-time-picker v-model:value="witForm.s_time" :value="moment(witForm.s_time,'HH:mm')" format="HH:mm" @change="checkWitForm('s_time')"/>
					</a-form-item>
					<a-form-item label="智控终止时间">
						<a-time-picker v-model:value="witForm.e_time" :value="moment(witForm.e_time,'HH:mm')" format="HH:mm" @change="checkWitForm('e_time')"/>
					</a-form-item>
				</template>
				<div class="add-local-timer-tip">当前设置-实时信息</div>
				<div class="add-local-timer-txt">
					{{witForm.desc}}
				</div>
			</a-form>
		</a-modal>

		<a-modal class="update-sensor" title="修改设备名称" v-model:visible="show.hostname" @ok="setHostName" @cancel="show.hostname= false"  width="700px">
			<a-form ref="hostNameForm" :model="hostNameForm" :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="设备名称">
					<a-input placeholder="请输入设备名称" v-model:value="hostNameForm.name"></a-input>
				</a-form-item>
				<a-form-item label="设备描述">
					<a-input placeholder="请输入设备描述" v-model:value="hostNameForm.remark"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal class="update-sensor" title="修改开关名称" v-model:visible="show.switch_name" @ok="saveSwitchName" @cancel="show.switch_name= false"  width="700px">
			<a-form ref="switchNameForm" :model="switchNameForm"  :label-col="{span:4}" :wrapper-col="{span:16}">
				<template v-for="(item,index) in switchNameForm.switch">
					<a-form-item :label="'开关'+(index+1)"  :key="index" v-if="index<4">
						<a-input placeholder="请输入开关名称" v-model:value="item.name"></a-input>
					</a-form-item>
				</template>
			</a-form>
		</a-modal>
		<a-modal class="update-sensor" title="设置" v-model:visible="show.more_switch" :footer="null" @cancel="show.more_switch= false"  width="700px">
			<a-form ref="moreSwitch" :label-col="{span:4}" :wrapper-col="{span:16}">
				<template v-for="(item,index) in device.giz_info.swInfo">
					<a-form-item :label="item.name" v-if="index>5" :key="index">
						<a-switch v-model:checked="item.value" @change="swOpen(item.value,item.key)"></a-switch>
					</a-form-item>
				</template>
			</a-form>
		</a-modal>
		<a-modal class="update-sensor" title="报警器设置" v-model:visible="show.siren" @ok="setSiren" @cancel="show.siren= false"  width="500px">
			<a-form ref="sirenForm" :model="sirenForm" :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="选择模式" v-if="sirenForm.type == 'mode'">
					<a-radio-group v-model:value="sirenForm.mode">
					    <a-radio :value="0">长响</a-radio>
					    <a-radio :value="1">快响</a-radio>
					    <a-radio :value="2">慢响</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="选择时间(秒)" v-if="sirenForm.type == 'time'">
					<a-input-number v-model:value="sirenForm.time" :min="1" :max="600"></a-input-number>
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal class="update-sensor" title="读值校正" v-model:visible="show.readrevise" @ok="setReadReVise" @cancel="show.readrevise= false"  width="700px">
			<div class="read-resive">
				<div class="read-resive-item" v-for="(item,index,key) in readResiveForm.value" :key="key">
					<div class="rri-title">{{ $gizRequest.getReadReviseTitle(index) }}:{{item}}mv</div>
					<a-slider v-model:value="readResiveForm.value[index]" :min="-1000" :max="1000"></a-slider>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>

import moment from 'moment';
import liveModel from '@/api/live';
import { $post } from '@/api/http'
import tool from '@/util/tool';
	var giz = null
	export default{
		data(){
			return{
				did:"",
				deviceList:[],	//设备列表
				device:null,	//设备信息
				witInfo:null,		//所有的智控信息
				witSwitchValue:false,	//本地智控开关锁
				show:{
					wit:false,
					siren:false,
					sensor:false,
					hostname:false,
					readrevise:false,
					switch_name:false,
					switch_mode:false,
					more_switch:false,
					add_local_time:false,
					add_network_time:false,
				},
				switchModeForm:{
					index:"",	//开关索引
					mode:"",	//开关模式
					time:"",	//设置时间 仅点动和延时模式有
				},
				//开关开启or关闭
				//切换传感器类型
				sensorForm:{
					type:"A",	//切换传感器 A or D 口
					a_select_1: "1",
					a_select_2: "1",
					d_select_1: "1",
					d_select_2: "1",
				},
				a_type_arr:[],	//A口传感器类型
				d_type_arr:[],	//D口传感器类型
				localTimer:{
					timer1:{ status:false,desc:null },
					timer2:{ status:false,desc:null },
					timer3:{ status:false,desc:null },
					timer4:{ status:false,desc:null },
					timer5:{ status:false,desc:null },
					timer6:{ status:false,desc:null },
					timer7:{ status:false,desc:null },
					timer8:{ status:false,desc:null },
					timer9:{ status:false,desc:null },
					timer10:{ status:false,desc:null },
				},
				//添加本地定时
				localTimerForm:{
					index:"",		//定时索引
					disabled:false,	//是否开启定时
					type:"",		//定时类型 分钟 小时 天
					s_time:"",		//定时开始时间
					e_time:"",		//定时结束时间
					action:0,		//执行动作
					sw:[],			//执行开关
					desc:"",		//描述
				},
				isLoading:false,

				//智控设置
				witForm:{
					accCom:"",		//精度
					max:0,
					min:0,
					name:"",		//当前传感器名称
					port:"",
					mode:"",		//触发动作
					val:[],			//上下限值
					val_s:0,		//上限
					val_e:0,		//下限
					s_time:"00:00",	//智控开始时间
					e_time:"00:00",	//智控结束时间
					sw:[],			//执行开关
					sw5:false,		//开关5/报警器
					openTime:false,	//是否开启时间区域控制
					desc:"",		//设置实时信息
				},

				//修改主机信息
				hostNameForm:{
					name:'',
					remark:""
				},
				switchNameForm:{
					switch:[],
				},
				sirenForm:{
					type:"mode",		//操作类型 mode=设置模式 time=设置时间
					mode:"",			//模式选项 0=长响 1=快响 2=慢响
					time:"",			//取值区间 [ 1,600 ] 秒
				},
				//读值校正
				readResiveForm:{
					value:[],
				}

			}
		},
		created() {
			let t = this
			t.did = t.$route.query.did
			t.device =JSON.parse(localStorage.getItem('__DEVICE__'))
			t.device.witSwitchValue = false
			// t.$set(t.device,"witSwitchValue",false)  //本地智控开关锁
			console.log("设备详情",t.device);

			if(  t.$gizRequest.giz ){
				giz = t.$gizRequest.giz
				let timer = setTimeout(function(){
					clearTimeout(timer)
					t.requestLocalTimerStatus()
				},1000)
				t.getDeviceDetail()
				t.getWitInfo()
			}else{
				t.$gizRequest.init(t).then(()=>{
					giz = t.$gizRequest.giz
					liveModel.open_getThingHostList(null,true,res=>{
						res.forEach(item=>{
							t.deviceList[item.did] = item
						})
						t.getDeviceDetail()
					})
				})
			}
		},
		methods:{
			getDeviceDetail(){
				let t = this ,{ did } = t

				// 渲染传感器类型
				t.a_type_arr = giz.sensor.getANames();
				t.d_type_arr = giz.sensor.getDNames();
				giz.handel({
				    open(evt) {
				        console.log("连接已经打开")
				    },
				    send(data) {
				        // console.log("发送指令")
				        // console.log(data)
				    },
				    close(evt) {
				        console.log("连接已经关闭");
				    },
				    // 登录事件
				    login(r) {
				        console.log("登录成功")
				        // 登录成功后，获取后端接口返回的在线设备列表并设置
						giz.did.addMany(t.deviceList)
						let timer = setTimeout(function(){
							clearTimeout(timer)
							t.requestLocalTimerStatus()
							t.getWitInfo()
						},500)
				    },

				    // 参数上报事件
				    attrs(_did_) {
						if( _did_ == did ){
							t.device.giz_info.swInfo[0].type = t.getSwMode(_did_,1)
							t.device.giz_info.swInfo[1].type = t.getSwMode(_did_,2)
							t.device.giz_info.swInfo[2].type = t.getSwMode(_did_,3)
							t.device.giz_info.swInfo[3].type = t.getSwMode(_did_,4)
							t.device.giz_info.swInfo[4].type = t.getSwMode(_did_,5)

							t.device.giz_info.swInfo[0].name = t.getSwitchName(0)
							t.device.giz_info.swInfo[1].name = t.getSwitchName(1)
							t.device.giz_info.swInfo[2].name = t.getSwitchName(2)
							t.device.giz_info.swInfo[3].name = t.getSwitchName(3)
							t.device.giz_info.swInfo[4].name = t.getSwitchName(4)

							t.device.giz_info.swInfo[0].value = giz.switch.getValue(_did_, 1)
							t.device.giz_info.swInfo[1].value = giz.switch.getValue(_did_, 2)
							t.device.giz_info.swInfo[2].value = giz.switch.getValue(_did_, 3)
							t.device.giz_info.swInfo[3].value = giz.switch.getValue(_did_, 4)
							t.device.giz_info.swInfo[4].value = giz.switch.getValue(_did_, 5)
							t.device.giz_info.swInfo[5].value = giz.switch.getValue(_did_, 6)
							t.device.giz_info.swInfo[6].value = giz.switch.getValue(_did_, 7)
							t.device.giz_info.swInfo[7].value = giz.switch.getValue(_did_, 8)
							t.device.giz_info.swInfo[8].value = giz.switch.getValue(_did_, 9)
							t.device.giz_info.swInfo[9].value = giz.switch.getValue(_did_, 10)
							t.device.witSwitchValue = giz.switch.getValue(_did_, 11)  //本地智控开关锁

							// 传感器值计算
							t.device.giz_info.AInfo['a1Info'] = giz.sensor.getAObject(_did_, 1)
							t.device.giz_info.AInfo['a2Info'] = giz.sensor.getAObject(_did_, 2)
							t.device.giz_info.AInfo['a3Info'] = giz.sensor.getAObject(_did_, 3)
							t.device.giz_info.AInfo['a4Info'] = giz.sensor.getAObject(_did_, 4)
							t.device.giz_info.AInfo['a5Info'] = giz.sensor.getAObject(_did_, 5)
							t.device.giz_info.DInfo['d1Info'] = giz.sensor.getDObject(_did_, 1)
							t.device.giz_info.DInfo['d2Info'] = giz.sensor.getDObject(_did_, 2)
							t.device.giz_info.DInfo['d3Info'] = giz.sensor.getDObject(_did_, 3)

							// console.log("设备信息",t.device);
						}
				    },
				})


			},

			getWitInfo(){
				let t = this ,{ giz_info } = t.device , witInfo = {
					a1:{port:"",info:null},
					a2:{port:"",info:null},
					a3:{port:"",info:null},
					a4:{port:"",info:null},
					a5:{port:"",info:null},
					d1:[],
					d2:[],
					d3:[],
				}
				if( giz_info.AInfo ){
					for ( let i in giz_info.AInfo ) {
						if( giz_info.AInfo[i].params && giz_info.AInfo[i].params.port ){
							if( i == "a1Info") witInfo.a1.port = giz_info.AInfo[i].params.port
							if( i == "a2Info") witInfo.a2.port = giz_info.AInfo[i].params.port
							if( i == "a3Info") witInfo.a3.port = giz_info.AInfo[i].params.port
							if( i == "a4Info") witInfo.a4.port = giz_info.AInfo[i].params.port
							if( i == "a5Info") witInfo.a5.port = giz_info.AInfo[i].params.port
						}
					}
				}

				if( giz_info.DInfo ){
					if( giz_info.DInfo.d1Info.params){
						giz_info.DInfo.d1Info.params.forEach(item=>{
							witInfo.d1.push( { port:item.port,info:null } )
						})
					}
					if( giz_info.DInfo.d3Info.params){
						giz_info.DInfo.d2Info.params.forEach(item=>{
							witInfo.d2.push( { port:item.port,info:null } )
						})
					}
					if( giz_info.DInfo.d3Info.params){
						giz_info.DInfo.d3Info.params.forEach(item=>{
							witInfo.d3.push( { port:item.port,info:null } )
						})
					}


				}
				giz.wit.getWit(t.did, witInfo.a1.port, function(r) {
				    witInfo.a1.info = r.getDesc()
				})
				giz.wit.getWit(t.did, witInfo.a2.port, function(r) {
				    witInfo.a2.info = r.getDesc()
				})
				giz.wit.getWit(t.did, witInfo.a3.port, function(r) {
				    witInfo.a3.info = r.getDesc()
				})
				giz.wit.getWit(t.did, witInfo.a4.port, function(r) {
				    witInfo.a4.info = r.getDesc()
				})
				giz.wit.getWit(t.did, witInfo.a5.port, function(r) {
				    witInfo.a5.info = r.getDesc()
				})

				witInfo.d1.forEach(item=>{
					giz.wit.getWit(t.did, item.port, function(r) {
					    item.info =r.getDesc()
					})
				})
				witInfo.d2.forEach(item=>{
					giz.wit.getWit(t.did, item.port, function(r) {
					    item.info =r.getDesc()
					})
				})
				witInfo.d3.forEach(item=>{
					giz.wit.getWit(t.did, item.port, function(r) {
					    item.info =r.getDesc()
					})
				})
				t.witInfo = witInfo
				console.log("智控",witInfo)
			},

			/**
			 * 获取开关模式
			 * @param {Object} _did		设备号
			 * @param {Object} index	开关索引
			 */
			getSwMode(_did,index) {
				let r = giz.switch.getMode(_did, index);
				if(r[0] === null) {
				    return "未知";
				}
				return r[0] + ":" + r[1];
			},

			/**
			 * 开关开启or关闭
			 * @param {Object} value  操作的值
			 * @param {Object} index  操作的开关的索引
			 */
			swOpen(value,index){
				console.log(value,index);
				if( value ){  //打开开关
					giz.switch.setOpen(this.did, index)
				}else{  //关闭开关
					 giz.switch.setClose(this.did, index)
				}
			},

			//设置开关模式
			showSwitchMode(index){
				this.switchModeForm = {
					index,
					mode:"",
					time:1,
				}
				this.show.switch_mode = true
			},

			//设置开关模式
			setSwitchMode(){
				let t = this , sw = t.switchModeForm
				if( sw.mode == 'switch'){
					 giz.switch.setMode(t.did, sw.index, "switch", 0);
				}
				if( sw.mode == 'lock'){
					 giz.switch.setMode(t.did, sw.index, "lock", 0);
				}
				if( sw.mode == 'time'){
					if( sw.time <= 0 ){
						t.$message.warning("请输入点动时间")
						return
					}
					giz.switch.setMode(t.did, sw.index, "time", sw.time );
				}
				if( sw.mode == 'delay'){
					if( sw.time <= 0 ){
						t.$message.warning("请输延时时间")
						return
					}
					giz.switch.setMode(t.did, sw.index, "delay", sw.time );
				}
				t.show.switch_mode = false
				t.$message.success("操作成功")
			},

			/**
			 * 传感器和智控操作
			 * @param {Object} action	操作类型 sensor=传感器 wit=智控
			 * @param {Object} type		传感器口 A or D
			 * @param {Object} data		传感器数据
			 * @param {Object} index	传感器索引
			 */
			showSensorForm(action,type,data,index){
				let t = this
				if( action == "sensor" ){
					this.sensorForm = {
						type,
						a_select_1:""+index,
						a_select_2: "1",
						d_select_1: ""+index,
						d_select_2: "1",
					}
					this.show.sensor = true
					return
				}

				if( action == 'wit' ){
					console.log("智控Form",data);
					let wit = null
					if( type == "A"){
						if( !data.params ){
							t.$message.warning('当前接口未设置传感器，暂时不能设置智控')
							return
						}
						wit = data.params
					}
					if( type == 'D' ) wit = data
					if( wit ){
						t.witForm.accCom = wit.accCom
						t.witForm.max = wit.max
						t.witForm.min = wit.min
						t.witForm.port = wit.port
						t.witForm.name = wit.name
						t.witForm.val = [wit.min,wit.max ]
					}

					giz.wit.getWit(t.did, wit.port, function(r) {
						console.log("获取到智控数据",r)
						let wit = r.getObject()
						if( wit ){
							let sw = []
							if( wit.sw_1 ) sw.push("sw1")
							if( wit.sw_2 ) sw.push("sw2")
							if( wit.sw_3 ) sw.push("sw3")
							if( wit.sw_4 ) sw.push("sw4")
							let s_time = "00:00" , e_time ="00:00"
							if( wit.s_time ){
								let hour = parseInt( wit.s_time / 60 )
								let miao = parseInt( wit.s_time % 60 )

								hour = hour <=9 ? ("0"+hour) :hour
								miao = miao <=9 ? ("0"+miao) :miao

								s_time = hour+':'+miao
							}

							if( wit.e_time ){
								let hour = parseInt( wit.e_time / 60 )
								let miao = parseInt( wit.e_time % 60 )

								hour = hour <=9 ? ("0"+hour) :hour
								miao = miao <=9 ? ("0"+miao) :miao

								e_time = hour+':'+miao
							}

							t.witForm.mode = wit.mode
							t.witForm.val = [wit.val_s  ,wit.val_e ]
							t.witForm.val_e = wit.val_e
							t.witForm.val_s = wit.val_s
							t.witForm.sw = sw
							t.witForm.sw_5 = wit.sw_5
							t.witForm.openTime = (wit.s_time && wit.e_time) ? true :false
							t.witForm.s_time = s_time
							t.witForm.e_time = e_time

						}else{
							t.witForm.mode = 0
							t.witForm.sw = []
							t.witForm.val = [t.witForm.min,t.witForm.max]
							t.witForm.val_e = t.witForm.max
							t.witForm.val_s = t.witForm.min
							t.witForm.sw_5 = false
							t.witForm.openTime = false
							t.witForm.s_time="00:00"
							t.witForm.e_time="00:00"
							t.witForm.desc=""
						}

						t.show.wit = true
					})
				}

				//删除智控
				if( action =='witdel' ){
					tool.confirm("确认删除该智控设置吗？").then(()=>{
						if( type == "A") giz.wit.delWit(t.did, data.params.port);
						if( type == "D") giz.wit.delWit(t.did, data.port);
						t.$message.success("操作完成")
					}).catch(()=>{})
				}
			},
			setSensor(){
				let t = this , { sensorForm } = t
				//切换A口传感器
				if( sensorForm.type == "A" ){
					giz.sensor.setAType(this.did, parseInt(sensorForm.a_select_1), parseInt(sensorForm.a_select_2))
				}
				//切换D口传感器
				if( sensorForm.type == "D" ){
					giz.sensor.setDType(this.did, parseInt(sensorForm.d_select_1), parseInt(sensorForm.d_select_2))
				}
				t.$message.success("操作成功")
				t.show.sensor = false
			},

			//请求获取定时任务
			requestLocalTimerStatus() {
				let t = this
			    giz.timer.getAllStatus(t.did, function(r) {
					console.log("获取任务状态",r);
			        t.localTimer.timer1 = {status:r.getStatus(1),desc:t.requestLocalTimerDesc(1)};
			        t.localTimer.timer2 = {status:r.getStatus(2),desc:t.requestLocalTimerDesc(2)};
			        t.localTimer.timer3 = {status:r.getStatus(3),desc:t.requestLocalTimerDesc(3)};
			        t.localTimer.timer4 = {status:r.getStatus(4),desc:t.requestLocalTimerDesc(4)};
			        t.localTimer.timer5 = {status:r.getStatus(5),desc:t.requestLocalTimerDesc(5)};
			        t.localTimer.timer6 = {status:r.getStatus(6),desc:t.requestLocalTimerDesc(6)};
			        t.localTimer.timer7 = {status:r.getStatus(7),desc:t.requestLocalTimerDesc(7)};
			        t.localTimer.timer8 = {status:r.getStatus(8),desc:t.requestLocalTimerDesc(8)};
			        t.localTimer.timer9 = {status:r.getStatus(9),desc:t.requestLocalTimerDesc(9)};
			        t.localTimer.timer10 = {status:r.getStatus(10),desc:t.requestLocalTimerDesc(10)};
			    })
			},

			// 请求获取任务描述
			requestLocalTimerDesc(index) {
			    let t = this;
			    giz.timer.getTimer(t.did, index, function(r) {
			        let desc = r.getDesc();
			        switch (index) {
			            case 1:
			               t.localTimer.timer1.desc= desc;
			                break;
			            case 2:
			               t.localTimer.timer2.desc= desc;
			                break;
			            case 3:
			               t.localTimer.timer3.desc= desc;
			                break;
			            case 4:
			               t.localTimer.timer4.desc= desc;
			                break;
			            case 5:
			               t.localTimer.timer5.desc= desc;
			                break;
			            case 6:
			               t.localTimer.timer6.desc= desc;
			                break;
			            case 7:
			               t.localTimer.timer7.desc= desc;
			                break;
			            case 8:
			               t.localTimer.timer8.desc= desc;
			                break;
			            case 9:
			               t.localTimer.timer9.desc= desc;
			                break;
			            case 10:
			               t.localTimer.timer10.desc = desc;
			                break;
			            default:
			                break;
			        }
			        // 重新询问状态
			        // _.requestLocalTimerStatus()
			    });
			},

			/**
			 * 设置本地定时的状态
			 * @param {Object} index 	本地定时索引
			 * @param {Object} status	本地定时状态
			 */
			setTimerStatus(index,status){
				let t = this
				status ? giz.timer.setOpen(t.did, index):giz.timer.setClose(t.did, index)
				let tm = setTimeout(function(){
					clearTimeout(tm)
					t.requestLocalTimerStatus()
				},300)
			},

			//删除定时
			delTimer(index){
				let t = this;
				giz.timer.delTimer(t.did, index) // 删除
				giz.timer.setClose(t.did, index)
				let tm = setTimeout(function(){
					clearTimeout(tm)
					t.requestLocalTimerDesc(index)
				},300)
			},

			/**
			 * 编辑本地定时任务信息
			 * @param {Object} index	本地定时索引
			 * @param {Object} status	本地定时状态
			 */
			showLocalTimer(index,status){
				let t = this
				giz.timer.getTimer(t.did,index, function(r) {
					console.log("获取到定时任务")
					let timer = r.getObject()
					if( timer ){
						let sw = []
						if( timer.sw_1 ) sw.push("sw1")
						if( timer.sw_2 ) sw.push("sw2")
						if( timer.sw_3 ) sw.push("sw3")
						if( timer.sw_4 ) sw.push("sw4")
						if( timer.sw_5 ) sw.push("sw5")

						let s_time = "00:00" , e_time ="00:00"
						if( timer.start_time ){
							let hour = parseInt( timer.start_time / 60 )
							let miao = parseInt( timer.start_time % 60 )

							hour = hour <=9 ? ("0"+hour) :hour
							miao = miao <=9 ? ("0"+miao) :miao

							s_time = hour+':'+miao
						}
						if( timer.stop_time ){
							let hour = parseInt( timer.stop_time / 60 )
							let miao = parseInt( timer.stop_time % 60 )

							hour = hour <=9 ? ("0"+hour) :hour
							miao = miao <=9 ? ("0"+miao) :miao

							e_time = hour+':'+miao
						}
						t.localTimerForm = {
							sw:sw,
							desc:"",
							type:timer.type,
							action:timer.action,
							s_time:s_time,
							e_time:e_time,
							index:index,
							disabled:status,
						}
					}else{
						t.localTimerForm = {
							sw:[],
							type:"",
							desc:"",
							action:0,
							s_time:"",
							e_time:"",
							index:index,
							disabled:status,
						}
					}
					t.show.add_local_time = true
				})

			},

			//检测添加本地定时表单是否填写完整
			checkLocalTimerForm(e){
				let t = this ,form = t.localTimerForm
				//切换类型后，分钟循环的执行动作重置一下
				if( form.type == 0 && e == 'type') {
					t.localTimerForm.sw = []
					t.localTimerForm.desc = ""
				}
				if( !form.disabled ) return
				if( form.s_time == "" ) return
				if( form.sw.length == 0 ) return

				//小时和天循环需要添加结束时间和执行动作判断
				if( form.type == 1 || form.type == 2){
					if( form.e_time == "" )return
				}
				let s_time1 = moment(form.s_time).format("HH:mm")
				let e_time1 = moment(form.e_time).format("HH:mm")

				let timer_str = s_time1.split(":")
				let sw1 = false,sw2 = false,sw3 = false,sw4 = false,sw5 = false

				if( form.sw.length > 0 ){
					if( form.sw.includes('sw1') ) sw1 = true
					if( form.sw.includes('sw2') ) sw2 = true
					if( form.sw.includes('sw3') ) sw3 = true
					if( form.sw.includes('sw4') ) sw4 = true
					if( form.sw.includes('sw5') ) sw5 = true
				}
				let sw_text = "【"
				if( sw1 ) sw_text+=t.device.giz_info.swInfo[0].name
				if( sw2 ) sw_text+=t.device.giz_info.swInfo[1].name
				if( sw3 ) sw_text+=t.device.giz_info.swInfo[2].name
				if( sw4 ) sw_text+=t.device.giz_info.swInfo[3].name
				if( sw5 ) sw_text+=t.device.giz_info.swInfo[4].name
				sw_text +="】"

				if( form.type == 0 ){  //分钟循环
					form.desc = `每${timer_str[1]}分触发一次${sw_text}`
				}
				if( form.type == 1 ){	//小时循环
					let timer_str2 = s_time1.split(":")
					if( form.action == 0 ){
						form.desc = `到达开始时间${timer_str[1]}分时关闭${sw_text}~到达结束时间${timer_str2[1]}分启动${sw_text}`
					}else{
						form.desc = `到达开始时间${timer_str[1]}分时启动${sw_text}~到达结束时间${timer_str2[1]}分关闭${sw_text}`
					}
				}
				if(form.type == 2){  //天循环
					if( form.action == 0 ){
						form.desc = `到达开始时间${s_time1}分时关闭${sw_text}~到达结束时间${e_time1}分启动${sw_text}`
					}else{
						form.desc = `到达开始时间${s_time1}分时启动${sw_text}~到达结束时间${e_time1}分关闭${sw_text}`
					}
				}
				t.localTimerForm = form
			},

			//设置本地定时
			setLocalTime(){
				let t = this , form = t.localTimerForm
				if( form.desc == "" ){
					t.$message.warning("信息填写不完整")
					return
				}
				let s_time1 = moment(form.s_time).format("HH:mm")
				let e_time1 =  moment(form.e_time).format("HH:mm")
				let timer_str = s_time1.split(":")
				let s_time = parseInt(timer_str[1])

				let sw1=false,sw2=false,sw3=false,sw4=false,sw5=false
				if( form.sw.includes('sw1') ) sw1 = true
				if( form.sw.includes('sw2') ) sw2 = true
				if( form.sw.includes('sw3') ) sw3 = true
				if( form.sw.includes('sw4') ) sw4 = true
				if( form.sw.includes('sw5') ) sw5 = true

				if( form.type == 0 ){
					if( s_time == 0 ){
						t.$message.warning("请选择时间")
						return
					}
					giz.timer.setTimer(this.did, form.index, form.type, 0, s_time, 0, sw1, sw2, sw3, sw4, sw5);
				}
				if( form.type == 1 ){
					let timer_str1 = e_time1.split(":")
					let e_time = parseInt(timer_str1[1])

					if( s_time == 0 || e_time == 0 ){
						t.$message.warning("请选择时间")
						return
					}

					giz.timer.setTimer(this.did, form.index, form.type, form.action, s_time, e_time, sw1, sw2, sw3, sw4, sw5);
				}

				if( form.type == 2 ){
					let timer_str1 = e_time1.split(":")
					let _e_time = parseInt(timer_str[0])*60+ parseInt(timer_str[1])
					let _e_time1 = parseInt(timer_str1[0]*60)+parseInt(timer_str1[1])
					giz.timer.setTimer(this.did, form.index, form.type, form.action, _e_time, _e_time1, sw1, sw2, sw3, sw4, sw5);
				}
				t.requestLocalTimerStatus() //重新加载定时任务信息
				t.show.add_local_time = false
			},

			//检测智控设置信息是否填写完整
			checkWitForm(type){
				let t = this ,wit = t.witForm
				if( type == "min" ){
					t.witForm.val = [wit.val_s,wit.val_e]
				}
				if( type == 'max' ){
					t.witForm.val_s = wit.val[0]
					t.witForm.val_e = wit.val[1]
				}

				if( wit.sw.length == 0 ) return
				let sw1 = false,sw2 =false ,sw3 = false ,sw4 = false , sw_txt =""
				if( wit.sw.includes('sw1') ) sw1 = true
				if( wit.sw.includes('sw2') ) sw2 = true
				if( wit.sw.includes('sw3') ) sw3 = true
				if( wit.sw.includes('sw4') ) sw4 = true
				if( sw1 ) sw_txt+=" "+t.device.giz_info.swInfo[0].name
				if( sw2 ) sw_txt+=" "+t.device.giz_info.swInfo[1].name
				if( sw3 ) sw_txt+=" "+t.device.giz_info.swInfo[2].name
				if( sw4 ) sw_txt+=" "+t.device.giz_info.swInfo[3].name

				// 上限启动/下限关闭 0
				let desc = `当【${wit.name}】高于上限${wit.val[0]}时${wit.mode == 0 ?'启动':'关闭'}【${sw_txt}】
							--低于下限${wit.val[1]}时${wit.mode == 0 ?'关闭':'启动'}【${sw_txt}】`
				if( wit.sw5 ) desc+="并报警一次"
				if( wit.openTime ) {
					let s_time = moment(wit.s_time).format("HH:mm")
					let e_time = moment(wit.e_time).format("HH:mm")
					desc+=`(在${s_time}~${e_time}之间的时间段会启用该智控)`
				}
				t.witForm.desc = desc
			},

			//设置智能控制
			setWit(){
				let t = this ,wit = t.witForm
				if( wit.openTime && (wit.s_time == wit.e_time) ){
					t.$message.warning("起始时间和终止时间不能相等！")
					return
				}
				let sw1 = false,sw2 =false ,sw3 = false ,sw4 = false
				if( wit.sw.includes('sw1') ) sw1 = true
				if( wit.sw.includes('sw2') ) sw2 = true
				if( wit.sw.includes('sw3') ) sw3 = true
				if( wit.sw.includes('sw4') ) sw4 = true
				let s_time1 = moment(wit.s_time).format("HH:mm")
				let e_time2 = moment(wit.e_time).format("HH:mm")

				let s_time_str =s_time1.split(':')
				let e_time_str =e_time2.split(':')
				let s_time = parseInt(s_time_str[0])*60 + parseInt(s_time_str[1])
				let e_time = parseInt(e_time_str[0])*60 + parseInt(e_time_str[1])
				giz.wit.setWit(t.did, wit.port, wit.mode, wit.val[0],wit.val[1], s_time, e_time, sw1, sw2, sw3, sw4, wit.sw5)
				let params = giz.did.getParams(t.did)
				$post("open_setThingHostParams",{did:t.did,params}).then(res=>{
					tool.message("操作完成")
					t.show.wit = false
				})
			},

			//设置主机名称
			setHostName(){
				let t = this , { name,remark } = t.hostNameForm
				if( name == "" ){
					tool.message("请输入主机名称")
					return
				}
				if( remark == "" ){
					tool.message("请输入主机描述","warning")
					return
				}
				$post("open_setThingHostName",{did:t.did,name:name,desc:remark}).then(res=>{
					t.device.dev_alias = name
					t.device.remark = remark
					localStorage.setItem("__DEVICE__",JSON.stringify(t.device))
					tool.message("设置成功")
					t.show.hostname = false
				})
			},

			//修改开关名称
			updateSwitchName(){
				this.switchNameForm.switch = giz.did.getParamSwitch(this.did)
				this.show.switch_name = true
			},
			saveSwitchName(){
				let t = this , data = t.switchNameForm.switch
				giz.did.setParamSwitch(t.did,data)
				let params = giz.did.getParams(t.did)
				giz.did.setParams(t.did,params)
				$post("open_setThingHostParams",{did:t.did,params}).then(res=>{
					t.device.giz_info.swInfo[0].name = t.getSwitchName(0)
					t.device.giz_info.swInfo[1].name = t.getSwitchName(1)
					t.device.giz_info.swInfo[2].name = t.getSwitchName(2)
					t.device.giz_info.swInfo[3].name = t.getSwitchName(3)
					t.device.giz_info.swInfo[4].name = t.getSwitchName(4)
					localStorage.setItem("__DEVICE__",JSON.stringify(t.device))
					tool.message("操作成功")
					t.show.switch_name = false
				})
			},

			/**
			 * 获取开关名称
			 * @param {Object} index
			 */
			getSwitchName(index){
				let sw = giz.did.getParamSwitch(this.did)
				if( sw.length >0 ){
					return sw[index].name
				}
			},

			//设置报警器
			showSiren(type){
				this.sirenForm.type = type
				this.show.siren = true
			},

			setSiren(){
				let t = this , { type,mode,time } = t.sirenForm
				if( type == "mode" ){
					if( !mode || mode == "" ){
						t.$message.warning("请选择模式")
						return
					}
					giz.switch.setBeepMode(t.did,mode)
				}
				if( type == "time" ){
					if( !time || time == "" ){
						t.$message.warning("请输入时间")
						return
					}
					giz.switch.setBeepTime(t.did,time)
				}
				tool.message("操作成功")
				t.show.siren = false
			},

			//读值校正
			showReadRevise(){
				let data = giz.did.getParamReviseSensor(this.did)
				console.log("读值校正",data);
				this.readResiveForm.value = data
				this.show.readrevise = true
			},
			setReadReVise(){
				let t = this ,{ value } = t.readResiveForm
				giz.did.setParamReviseSensor(t.did,value)
				let params = giz.did.getParams(t.did)
				giz.did.setParams(t.did,params)
				$post("open_setThingHostParams",{did:t.did,params}).then(res=>{
					tool.message("操作成功")
					t.show.readrevise = false
				})
			}
		},

	}
</script>

<style lang="scss">
	.device-detail{
		.ddb-info{
			display: flex;
			justify-content: space-between;
			align-items: center;
			&-name{
				font-weight: bold;
				font-size: 16px;
			}
			&-did{
				color: #999;
				font-size: 12px;
			}
		}

		.ddb-switch{
			width: 100%;
			height: 50px;
			display: flex;
			align-items: center;
			margin-bottom: 24px;
			&-title{
				font-weight: bold;
				margin-right: 24px;
			}
			&-val{
				>span{
					margin-right: 24px;
					cursor: pointer;
				}
			}
		}
		.ddb-open{
			display: flex;
			&-til{
				width: 100px;
			}
			&-val{
				>span{
					margin-right: 12px;
					font-size: 14px;
					margin-left: 4px;
					cursor: pointer;
				}
			}
		}

		.ddb-sensor{
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			&-item{
				width: 24%;
				border: 1px solid #008000;
				position: relative;
				cursor: pointer;
				background-color: #3A6AF2;
				margin-bottom: 12px;

				&-top{
					padding:0 5%;
					height: 80px;
					line-height: 80px;
					background: #FFFFFF;
					span{
						font-weight: bold;
					}
				}
				&-btm{
					width: 90%;
					padding: 10px 5%;
					color: #FFFFFF;
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					font-size: 12px;
					line-height: 24px;

					>div{
						width: 46%;
						height: 24px;
						margin-right: 12px;
						display: flex;
						div{
							width: 50%;
						}
						div:last-child{
							font-weight: bold;
						}
					}
				}



				&-edit{
					width: 30px;
					height: 30px;
					background: #38B44A;
					text-align: center;
					line-height: 30px;
					position: absolute;
					right: 0;
					border-radius: 0 0 0 30px;
					color: #FFFFFF;
				}

				&-type{
					width: 50px;
					height: 50px;
					text-align: center;
					border-radius: 50%;
					background: rgba($color: #5262FF, $alpha: .2);
					line-height: 50px;
					position: absolute;
					margin-left: 80%;
					margin-top: 20px;
					img{
						position: relative;
						top: 7px;
					}
				}
			}
		}

		.ddb-tables{
			width: 100%;
			box-sizing: border-box;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 24px;
			.ddbti-nav{
				width: 100%;
				height: 40px;
				line-height: 40px;
				text-align: center;
				background: #3A6AF2;
				color: #FFFFFF;
				box-sizing: border-box;
			}
			&-item{
				width: 48%;
				height: 440upx;
				background: #FFFFFF;
				border: 3px solid #3A6AF2;

				.ddbti-item{
					width: 100%;
					height: 40px;
					line-height: 40px;
					border-bottom: 1px solid #f4f4f4;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 24px;
					box-sizing: border-box;

					&-index{

						width: 24px;
						height: 24px;
						border-radius: 50%;
						background: #CC0000;
						color: #fff;
						text-align: center;
						line-height: 24px;
						font-size: 12px;
					}

					&-desc{
						width: 70%;
						cursor: pointer;
						font-size: 13px;
						color: #999;
						text-align: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					&-switch{
						width: 20%;
						text-align: right;
					}
				}
			}

			.ddb-tables-item-two{
				border: 3px solid #FFCC03;
				margin-right: 10px;
				.ddbti-nav-two{
					width: 100%;
					height: 40px;
					line-height: 40px;
					text-align: center;
					background: #FFCC03;
					color: #FFFFFF;
				}
				.ddbti-collapse-title{
					padding-left: 24px;
				}
			}
		}

		.add-network-timer,
		.add-local-timer,
		.update-switch-mode,
		.update-sensor{
			.el-dialog{
				border: 3px solid #396AF2;
			}
			.el-dialog__header{
				background:  #396AF2;
				padding: 10px 20px 10px;
				.el-dialog__title{
					color: #FFFFFF;
				}
				.el-dialog__headerbtn{
					top: 15px;

					.el-dialog__close{
						color: #ffffff;
					}
				}
			}
		}
		.add-local-timer{
			.el-dialog{
				border: 3px solid #38B44A;
			}
			.el-dialog__header{
				background:  #38B44A;
			}
			.el-dialog__body{
				padding: 0;
			}

			&-tip{
				width: 90%;
				padding: 0 5%;
				height: 35px;
				line-height: 35px;
				background: #EEEEEE;
			}
			&-txt{
				width: 80%;
				padding:0 10%;
				font-size: 12px;
				margin: 24px 0;

			}
		}

		.update-switch-mode{
			.usm-item{
				display: flex;
				align-items: center;
				span{
					font-size: 12px;
					color: #999999;
				}
				i{
					margin:0 8px;
				}
			}
		}

	}


	.sensor-op{
		cursor: pointer;
		line-height: 32px;

		&-item:hover{
			color: #0080FF;
		}
	}

	.el-popover{
		min-width: 100px;
	}
	.read-resive{
		height: 500px;
		overflow: hidden;
		overflow-y: auto;
		.read-resive-item{
			width: 100%;
			.rri-title{
				color: #999;
				font-size: 12px;
			}
		}
	}


</style>
